// import logo from "./logo.svg";
import React from "react";
import "./App.css";

function App() {
  React.useEffect(() => {
    let vid = document.getElementById("autoplay");
    vid.play();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <video
          width="360"
          autoPlay={true}
          id="autoplay"
          muted
          style={{
            mixBlendMode: "lighten",
          }}
        >
          <source src="/intro.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <p>Revamp in progress!</p>
      </header>
    </div>
  );
}

export default App;
